import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import useIsMountedRef from "../../utils/mountedRef";
import { useSnackbar } from "notistack";
import "./SchemaFiscalite.css";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ProcessPDF from "./processPdf";
import LateralMenu from "../../layouts/lateralMenu/lateralMenu";

const ProcessPatrimoine = (props) => {
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [result, setResult] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const [categories, setCategories] = useState([]);
  const [processData, setProcessData] = useState(null);

  useEffect(() => {
    const config = {
      method: "get",
      url: `${API_BASE_URL}/categories`,
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(localStorage.getItem("user"))?.jwt,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200 && response.data != null) {
          if (isMountedRef.current) {
            setCategories(response.data);
          }
        } else {
          enqueueSnackbar("Une erreur est survenue", { variant: "error" });
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          if (error.response.data?.error === "Other_user_connected") {
            localStorage.removeItem("user");
            localStorage.setItem("other_user_connected", "other_user_connected");
            window.location.href = "/login";
          } else if (["Never connected", "Unauthorized"].includes(error.response.data?.error)) {
            localStorage.removeItem("user");
            window.location.href = "/login";
          }
        }
      });
  }, [isMountedRef, enqueueSnackbar]);

  useEffect(() => {
    const id = props.match.params.id;

    if (id) {
      const fetchData = async () => {
        try {
          const response = await fetch(`/decisionProcess/process${id}.json`);
          if (response.ok) {
            const data = await response.json();
            setProcessData(data);
          } else {
            console.error("Error fetching JSON data:", response.statusText);
          }
        } catch (error) {
          console.error("Error loading process data:", error);
        }
      };

      fetchData();
    }
  }, [props.match.params.id]);

  if (processData == null) {
    return null;
  }

  const handleChoiceSelect = (choice, questionIndex) => {
    const updatedChoices = [
      ...selectedChoices.slice(0, questionIndex),
      { question: getCurrentQuestion(questionIndex), choice },
    ];
    setSelectedChoices(updatedChoices);
    setResult(null);

    if (choice.subquestions?.length > 0) {
    } else if (choice.result) {
      const results = Array.isArray(choice.result) ? choice.result : [choice.result];
      setResult(results);
    }
  };

  const getCurrentQuestion = (index) => {
    if (index === 0) {
      return processData.questions[0];
    } else {
      const previousChoice = selectedChoices[index - 1]?.choice;

      // Vérifier s'il existe des sous-questions dans le choix précédent
      if (previousChoice?.subquestions && previousChoice.subquestions.length > 0) {
        return previousChoice.subquestions[0];
      } else if (previousChoice?.result) {
        // Si le choix précédent a un résultat, alors aucune nouvelle question n'est nécessaire
        return null;
      }
      return null;
    }
  };

  const renderArrow = () => (
    <div className="fleches">
      <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.41 65.17" height="65px">
        <line className="fleche2" x1="3.72" y1="59.2" x2="3.27" y2="0" />
        <polygon className="fleche1" points="7.41 56.36 3.72 57.97 0 56.42 3.77 65.17 7.41 56.36" />
      </svg>
    </div>
  );

  const handleSimulatorClick = (id) => {
    const category = categories[0];
    if (category?.url_name) {
      window.location.href = `/categorie/${category.url_name}/simulateur/` + id;
    } else {
      console.error("URL name for category is missing.");
    }
  };
  const renderResults = () => {
    const isMultipleResults = props.match.params.id === "3";

    return (
      <div className="schema_option_block_result">
        {/* Si plusieurs résultats comme schéma TVA */}
        {isMultipleResults ? (
          <>
            <div className="schema-selected-options-results-block">
              {result?.length > 0 ? (
                <div className="result_title_DE_block">
                  <div className="result_title_DE_sous_block">
                    <div>
                      {result?.[0]?.subResultTitle1?.length > 0 ? (
                        <div>
                          {result[0].subResultTitle1[0]?.resultTitle1 && (
                            <span className="schema_result_title">{result[0].subResultTitle1[0].resultTitle1}</span>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="schema-selected-options-results-button-block-tva">
                      <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                        {Array.isArray(result[0]?.subResultTitle1) ? (
                          result[0].subResultTitle1.map((subResult, index) => (
                            <div key={index} style={{ marginBottom: "10px" }}>
                              <div
                                style={{
                                  height: subResult.result1?.some((res) => res.subtitle) ? "240px" : "120px", // Vérifie si un `subtitle` existe
                                 }}
                              >
                                <div
                                  style={{
                                    marginTop: "10px",
                                    flexDirection: "row",
                                    alignItems: "flex-start",
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "10px",
                                  }}
                                >
                                  {subResult.result1?.map((res, idx) => (
                                    <div
                                      key={idx}
                                      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                                    >
                                      {res.title && (
                                        <span
                                          className="schema-selected-options-results-button-tva"
                                          style={{ backgroundColor: "#325538", marginBottom: "5px" }}
                                        >
                                          {res.title}
                                        </span>
                                      )}
                                      {res.subtitle && (
                                        <>
                                          {renderArrow()}
                                          <span
                                            className="schema-selected-options-results-button-tva"
                                            style={{ backgroundColor: "#1b2b1e" }}
                                          >
                                            {res.subtitle}
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  ))}
                                </div>

                                {renderArrow()}
                              </div>

                              <div style={{ borderBottom: "1px solid #000000", width: "100%", margin: "10px 0px" }} />

                              {subResult.subResultTitle2?.length > 0 && (
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                  {subResult.subResultTitle2[0]?.resultTitle2 && (
                                    <div style={{ textAlign: "center", marginBottom: "10px" }}>
                                      <span className="schema_result_title">
                                        {subResult.subResultTitle2[0].resultTitle2}
                                      </span>
                                    </div>
                                  )}
                                  <div
                                    style={{
                                      display: subResult.subResultTitle2.length > 1 ? "flex" : "block",
                                      flexDirection: "row",
                                      gap: "20px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {subResult.subResultTitle2.map((subItem, subIdx) => (
                                      <div
                                        key={subIdx}
                                        style={{
                                          flex: "1",
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                        }}
                                      >
                                        {subItem.result2 && (
                                          <div
                                            className="schema-selected-options-results-button-other"
                                            dangerouslySetInnerHTML={{ __html: subItem.result2 }}
                                            style={{ marginBottom: "10px", backgroundColor: "#693219" }}
                                          />
                                        )}
                                        {subItem.result3 && (
                                          <>
                                            {renderArrow()}
                                            <span
                                              className="schema-selected-options-results-button-block"
                                              style={{ minHeight: "80px", textAlign: "center" }}
                                            >
                                              <span className="schema-selected-options-results-button-other">
                                                <span dangerouslySetInnerHTML={{ __html: subItem.result3 }} />{" "}
                                                {subItem.ref1}
                                              </span>
                                            </span>
                                          </>
                                        )}
                                        {renderArrow()}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}

                              <div style={{ borderBottom: "1px solid #000000", width: "100%", margin: "10px 0px" }} />

                              {/* Simulateurs - Calculs */}
                              <span
                                className="schema_result_title"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                Pour aller plus loin
                              </span>

                              {subResult.subResultTitle2?.filter((subItem) => subItem.result2)?.length > 1 ? (
                                <>
                                  {subResult.subResultTitle2?.some((subItem) => subItem.simulators) && (
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        flexDirection: "row",
                                        alignItems: "flex-start",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "10px",
                                      }}
                                    >
                                      {subResult.subResultTitle2.flatMap((subItem) =>
                                        subItem.simulators?.map((simulator) => (
                                          <div key={simulator.id} className="schema-selected-options-results-button">
                                            <span className="schema_result_title">Calculer</span>
                                            <span
                                              className="simulator_links"
                                              onClick={() => handleSimulatorClick(simulator.id)}
                                            >
                                              {simulator.title}
                                            </span>
                                          </div>
                                        ))
                                      )}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>  {subResult.subResultTitle2?.some((subItem) => subItem.simulators) && (
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      flexDirection: "column",

                                      display: "flex",
                                    }}
                                  >
                                    {subResult.subResultTitle2.flatMap((subItem) =>
                                      subItem.simulators?.map((simulator) => (
                                        <div key={simulator.id} className="schema-selected-options-results-button">
                                          <span className="schema_result_title">Calculer</span>
                                          <span
                                            className="simulator_links"
                                            onClick={() => handleSimulatorClick(simulator.id)}
                                          >
                                            {simulator.title}
                                          </span>
                                        </div>
                                      ))
                                    )}
                                  </div>
                                )}</>
                              )}
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p>Aucun résultat trouvé.</p>
              )}
            </div>

            <span className="chema-selected-options-results-buttonPDF-box">
              <span className="schema_result_title">Télécharger votre résultat </span>
              <PDFDownloadLink
                document={<ProcessPDF title={processData.title} selectedChoices={selectedChoices} result={result} />}
                fileName="résumé_synthèse.pdf"
                className="schema-selected-options-results-buttonPDF"
              >
                {({ loading }) => (loading ? "Génération du PDF..." : "Télécharger votre résultat")}
              </PDFDownloadLink>
              {console.log("Résultat dans ProcessPDF :", result)}
            </span>
          </>
        ) : (
          // dans les autres cas
          <>
            {(Array.isArray(result) ? result : [result]).map((res, index) => (
              <div key={index} className="schema-selected-options-results-block">
                <div className="result_title_DE_block">
                  <h2>Vos résultats</h2>
                  <div className="result_title_DE_sous_block">
                    {res.resultTitle1 && (
                      <span className="schema-selected-options-results-button-block">
                        <span className="schema_result_title">{res.resultTitle1}</span>
                        <span className="schema-selected-options-results-button-tva">{res.result1}</span>
                      </span>
                    )}
                    {res.resultTitle2 && (
                      <span className="schema-selected-options-results-button-block">
                        <span className="schema_result_title">{res.resultTitle2}</span>
                        <span
                          className="schema-selected-options-results-button-tva"
                          dangerouslySetInnerHTML={{ __html: res.result2 }}
                        />
                      </span>
                    )}
                    {res.resultTitle3 && (
                      <span className="schema-selected-options-results-button-block">
                        <span className="schema_result_title">{res.resultTitle3}</span>
                        <span className="schema-selected-options-results-button-tva">
                          {res.result3} <br /> {res.ref1}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
                <div className="pour_aller_plus_loin_block">
                  <h2>Pour aller plus loin</h2>
                  {res.simulators && res.simulators.length > 0 && (
                    <span className="schema-selected-options-results-button-block">
                      <span className="schema_result_title">Réaliser votre calcul</span>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {res.simulators.map((simulator) => (
                          <div className="schema-selected-options-results-button" key={simulator.id}>
                            <span className="simulator_links" onClick={() => handleSimulatorClick(simulator.id)}>
                              {simulator.title}
                            </span>
                          </div>
                        ))}
                      </div>
                    </span>
                  )}
                  <span className="pour_aller_plus_loin_block2">
                    <span className="chema-selected-options-results-buttonPDF-box">
                      <span className="schema_result_title">Télécharger votre résultat </span>
                      <PDFDownloadLink
                        document={
                          <ProcessPDF title={processData.title} selectedChoices={selectedChoices} result={result} />
                        }
                        fileName="résumé_synthèse.pdf"
                        className="schema-selected-options-results-buttonPDF"
                      >
                        {({ loading }) => (loading ? "Génération du PDF..." : "Télécharger votre résultat")}
                      </PDFDownloadLink>
                    </span>
                  </span>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="page">
      <LateralMenu />
      <div className="schema_global_composant">
        <div className="schema_main_title">{processData?.title}</div>

        {selectedChoices.map((choiceObj, index) => (
          <div key={index}>
            <div className="schema_title">{choiceObj.question.question}</div>
            <div className="schema_option_block">
              {choiceObj.question.choices.map((choice, idx) => (
                <span
                  key={idx}
                  className={`schema_option_button ${choice.choice === choiceObj.choice.choice ? "selected" : ""}`}
                  onClick={() => handleChoiceSelect(choice, index)}
                >
                  <b>
                    <span dangerouslySetInnerHTML={{ __html: choice.choice }} />
                  </b>
                </span>
              ))}
            </div>
            {renderArrow()}
          </div>
        ))}

        {!result && getCurrentQuestion(selectedChoices.length) ? (
          <div style={{ marginTop: "20px" }}>
            {getCurrentQuestion(selectedChoices.length)?.question && (
              <>
                <div className="schema_title">{getCurrentQuestion(selectedChoices.length)?.question}</div>
                <div className="schema_option_block">
                  {getCurrentQuestion(selectedChoices.length)?.choices.map((choice, index) => (
                    <span
                      key={index}
                      className="schema_option_button"
                      onClick={() => handleChoiceSelect(choice, selectedChoices.length)}
                    >
                      <b>
                        <span dangerouslySetInnerHTML={{ __html: choice.choice }} />
                      </b>
                    </span>
                  ))}
                </div>
              </>
            )}
          </div>
        ) : (
          result && renderResults()
        )}
      </div>
    </div>
  );
};

export default ProcessPatrimoine;
